button.e-btn.raf-ProfileCardDDB.raf_profile_card {
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.2); // Variable color with 20% opacity
    border-color: transparent;
    padding: 0.5rem 0.75rem !important;
    width: 100%;
    text-align: left !important;
    line-height: normal !important;
    min-height: unset !important;
    max-height: unset !important;
    height: auto !important;
    border-radius: 0.5rem;

    & .border-avatar {
        border-color: #fff !important;

        & .fa {
            color: #fff;
        }
    }

    & .e-caret {
        color: #fff;
    }
}

.raf_profile_card.e-dropdown-popup:not(.e-popup-open) {
    display: none;
}